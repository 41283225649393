import React from "react";

import { MainLayout } from "../../layout";

export default function JoinUs() {
  return (
    <MainLayout>
      <h1>Join Us</h1>
    </MainLayout>
  );
}
